export const appendZero = (value: number) => {
    let transacitonValue = value.toString();
    const decimalPlaceRegexCheck = /^-?(\d+)?([.]?\d{0,1})?$/;
    if (
        transacitonValue.includes('.') &&
        decimalPlaceRegexCheck.test(transacitonValue)
    ) {
        return `${transacitonValue}0`;
    } else if (transacitonValue.includes('.')) {
        return transacitonValue;
    } else {
        return `${transacitonValue}.00`;
    }
};